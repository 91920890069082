/**
 * This file is a declaration of common/shared values across the frontend.
 * The .prod.ts version replaces this when a production build is run, thus the production
 * client will have values from .prod.ts.
 */
export const environment = Object.seal({
    termsOfUseVersion: "May 4, 2022",
    // We aren't running in production mode.
    production: false,

    // The domains do we trust to safely run in iframes
    trustedDomains: [
        "blog.services.dynatrace.com",
        "docs.services.dynatrace.com",
        "dynatrace.com",
        "www.dynatrace.com"
    ]
});

// Improved errors. We do not want the extra payload and processing in production.
import 'zone.js/plugins/zone-error';
